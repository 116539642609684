<template>
  <v-container>
    <app-loading v-if="loading"/>
    <v-card v-else>
      <v-card-title>{{ $vuetify.lang.t('$vuetify.partners.title') }}</v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="formValid"
          style="padding: 0"
          lazy-validation
        >
          <v-row>
            <v-col
              cols="6"
              md="4"
              sm="12"
            >
              <avatar-picker
                :image-src="getAvatar"
                :image-style="{
                'border-radius': '50%',
                height: '10em',
                width: '10em'
              }"
                class="profile mx-auto d-block"
                @input="onChangeImage($event)"
              />
            </v-col>
            <v-col
              md="8"
              cols="6"
              sm="12"
            >
              <v-row>
                <v-col
                  md="6"
                  cols="12"
                >
                  <v-text-field
                    v-model="partner.name"
                    :disabled="showExtraData"
                    :label="$vuetify.lang.t('$vuetify.firstName')"
                    required
                    :rules="formRule.required"
                  />
                </v-col>
                <v-col
                  md="6"
                  cols="12"
                >
                  <v-text-field
                    v-model="partner.last_name"
                    :disabled="showExtraData"
                    required
                    :rules="formRule.required"
                    :label="$vuetify.lang.t('$vuetify.lastName')"
                  />
                </v-col>
                <v-col
                  md="6"
                  cols="12"
                >
                  <vue-tel-input-vuetify
                    v-model="partner.phone"
                    :disabled="showExtraData"
                    v-bind="bindProps"
                    :error-messages="errorPhone"
                    :label="
                    $vuetify.lang.t(
                      '$vuetify.phone'
                    )
                  "
                    :placeholder="
                    $vuetify.lang.t(
                      '$vuetify.phone_holder'
                    )
                  "
                    :prefix="
                    countrySelect
                      ? `+` +
                        countrySelect.dialCode
                      : ``
                  "
                    :select-label="
                    $vuetify.lang.t(
                      '$vuetify.country'
                    )
                  "
                    required
                    @input="onInput"
                    @country-changed="onCountry"
                  >
                    <template
                      #message="{ key, message }"
                    >
                      <slot
                        v-bind="{ key, message }"
                        name="label"
                      />
                      {{ message }}
                    </template>
                  </vue-tel-input-vuetify>
                </v-col>
                <v-col
                  md="6"
                  cols="12"
                >
                  <v-text-field
                    v-model="partner.email"
                    :disabled="showExtraData"
                    :label="
                    $vuetify.lang.t(
                      '$vuetify.email'
                    )
                  "
                    :rules="formRule.email"
                  />
                </v-col>

                <v-col
                  md="6"
                  cols="12"
                >
                  <v-text-field
                    v-model="partner.password"
                    :append-icon="
                  hidePassword1 ? 'mdi-eye' : 'mdi-eye-off'
                "
                    :label="$vuetify.lang.t('$vuetify.password')"
                    :rules="formRule.password"
                    :type="hidePassword1 ? 'password' : 'text'"
                    autocomplete="off"
                    name="password"
                    required
                    @click:append="hidePassword1 = !hidePassword1"
                  />
                </v-col>
                <v-col
                  md="6"
                  cols="12"
                >
                  <v-text-field
                    v-model="partner.password_confirmation"
                    :append-icon="
                  hidePassword2 ? 'mdi-eye' : 'mdi-eye-off'
                "
                    :label="
                  $vuetify.lang.t('$vuetify.confirm_password')
                "
                    :rules="passwordConfirmation"
                    :type="hidePassword2 ? 'password' : 'text'"
                    autocomplete="off"
                    name="password_confirmation"
                    required
                    @click:append="hidePassword2 = !hidePassword2"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col
              md="12"
              cols="12"
            >
              <v-row>
                <v-col
                  md="4"
                  cols="12"
                >
                  <v-menu
                    ref="menu1"
                    v-model="menu"
                    :disabled="showExtraData"
                    required
                    :rules="formRule.required"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="partner.date_born"
                        :disabled="showExtraData"
                        :label="$vuetify.lang.t('$vuetify.bornDate')"
                        :hint="$vuetify.lang.t('$vuetify.hints.format_date')"
                        required
                        :rules="formRule.required"
                        persistent-hint
                        v-bind="attrs"
                        @blur="date = parseDate(partner.date_born)"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="date"
                      no-title
                      @input="menu = false"
                    />
                  </v-menu>
                </v-col>
                <v-col
                  md="4"
                  cols="12"
                >
                  <v-select
                    v-model="partner.sex"
                    :disabled="showExtraData"
                    item-text="text"
                    item-value="value"
                    :items="getSex"
                    :label="$vuetify.lang.t('$vuetify.sex.text')"
                    required
                    :rules="formRule.required"
                  />
                </v-col>
                <v-col
                  md="4"
                  cols="12"
                >
                  <v-select
                    v-model="partner.level_student"
                    :disabled="showExtraData"
                    item-text="text"
                    item-value="value"
                    :items="getLevelStudent"
                    :label="$vuetify.lang.t('$vuetify.level_student.text')"
                    required
                    :rules="formRule.required"
                  />
                </v-col>
                <v-col
                  md="12"
                  cols="12"
                >
                  <v-text-field
                    v-model="partner.address"
                    :disabled="showExtraData"
                    :label="$vuetify.lang.t('$vuetify.address')"
                    :rules="formRule.required"
                  />
                </v-col>
                <v-col
                  md="4"
                  cols="12"
                >
                  <v-text-field
                    v-model="partner.city"
                    required
                    :disabled="showExtraData"
                    :label="$vuetify.lang.t('$vuetify.city')"
                    :rules="formRule.required"
                  />
                </v-col>
                <v-col
                  md="4"
                  cols="12"
                >
                  <v-text-field
                    v-model="partner.province"
                    :disabled="showExtraData"
                    :label="$vuetify.lang.t('$vuetify.province')"
                  />
                </v-col>
                <v-col
                  md="4"
                  cols="12"
                >
                  <v-text-field
                    v-model="partner.municipality"
                    :disabled="showExtraData"
                    :label="$vuetify.lang.t('$vuetify.municipality')"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  v-if="showExtraData"
                  md="6"
                  cols="12"
                >
                  <v-select
                    v-model="partner.state"
                    item-text="text"
                    item-value="value"
                    :items="getState"
                    :label="$vuetify.lang.t('$vuetify.state')"
                    required
                    :rules="formRule.required"
                  />
                </v-col>
                <v-col
                  v-if="showExtraData"
                  md="6"
                  cols="12"
                >
                  <div>{{ $vuetify.lang.t('$vuetify.menu.pasarela_config') }}</div>
                  <v-radio-group
                    v-model="partner.gateway"
                    row
                  >
                    <v-radio
                      v-for="n in getGateway"
                      :key="n.value"
                      :label="n.text"
                      :value="n.value"
                    />
                  </v-radio-group>
                </v-col>
                <v-col
                  v-if="partner.state === 'denied' || partner.state === 'blocked'"
                  md="12"
                  cols="12"
                >
                  <v-text-field
                    v-model="partner.description_inactive"
                    :label="$vuetify.lang.t('$vuetify.description')"
                    required
                    :rules="formRule.required"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="showExtraData"
          class="mb-2"
          @click="cancelShowModal"
        >
          <v-icon>mdi-close</v-icon>
          {{ $vuetify.lang.t('$vuetify.actions.cancel') }}
        </v-btn>
        <v-btn
          v-if="!showExtraData"
          class="mb-2"
          color="primary"
          :disabled="!formValid || loading"
          @click="solicitPartner"
        >
          {{ $vuetify.lang.t('$vuetify.actions.send') }}
        </v-btn>
        <v-btn
          v-if="showExtraData"
          class="mb-2"
          color="primary"
          :disabled="!formValid"
          @click="updatePartnerData"
        >
          {{ $vuetify.lang.t('$vuetify.actions.send') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import localStorage from '../../config/localStorage'
import AppLoading from '../../components/core/AppLoading'

export default {
  name: 'PartnerInfo',
  components: { AppLoading },
  props: {
    showExtraData: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      menu: false,
      state: 'solicited',
      location: '',
      date: new Date().toISOString().substr(0, 10),
      dateFormatted: null,
      searchResults: [],
      service: null,
      formValid: false,
      hidePassword1: true,
      hidePassword2: true,
      countrySelect: null,
      errorPhone: null,
      formRule: this.$rules,
      passwordConfirmation: [
        v =>
          !!v ||
        this.$vuetify.lang.t('$vuetify.rule.required', [
          this.$vuetify.lang.t('$vuetify.confirm_password')
        ]),
        v =>
          (!!v && v) === this.partner.password ||
        this.$vuetify.lang.t('$vuetify.rule.match', [this.$vuetify.lang.t('$vuetify.password')],
          [this.$vuetify.lang.t('$vuetify.confirm_password')]
        )
      ]
    }
  },
  computed: {
    ...mapState('auth', ['userData', 'partner']),
    getGateway () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.partner.gateway = this.partner.gateway ? this.partner.gateway : 'stripe'
      return [
        { value: 'stripe', text: this.$vuetify.lang.t('$vuetify.gateway.stripe') },
        { value: 'paypal', text: this.$vuetify.lang.t('$vuetify.gateway.paypal') }
      ]
    },
    getSex () {
      return [
        { value: 'F', text: this.$vuetify.lang.t('$vuetify.sex.f') },
        { value: 'M', text: this.$vuetify.lang.t('$vuetify.sex.m') },
        { value: 'O', text: this.$vuetify.lang.t('$vuetify.sex.o') }
      ]
    },
    getLevelStudent () {
      return [
        { value: 'primary', text: this.$vuetify.lang.t('$vuetify.level_student.primary') },
        { value: 'preparatory', text: this.$vuetify.lang.t('$vuetify.level_student.preparatory') },
        { value: 'university', text: this.$vuetify.lang.t('$vuetify.level_student.university') },
        { value: 'master', text: this.$vuetify.lang.t('$vuetify.level_student.master') },
        { value: 'doctor', text: this.$vuetify.lang.t('$vuetify.level_student.doctor') },
        { value: 'other', text: this.$vuetify.lang.t('$vuetify.level_student.other') }
      ]
    },
    getState () {
      return [
        { value: 'approved', text: this.$vuetify.lang.t('$vuetify.sale.state.approved') },
        { value: 'denied', text: this.$vuetify.lang.t('$vuetify.sale.state.denied') },
        { value: 'blocked', text: this.$vuetify.lang.t('$vuetify.sale.state.blocked') }
      ]
    },
    computedDateFormatted () {
      return this.formatDate(this.date)
    },
    getAvatar () {
      return this.partner.avatar ? this.partner.avatar.id ? this.partner.avatar.original_url : this.partner.avatar : '/assets/avatar/avatar-undefined.jpg'
    },
    bindProps () {
      return {
        mode: 'national',
        clearable: true,
        defaultCountry: this.partner.country || 'US',
        disabledFetchingCountry: false,
        autocomplete: 'off',
        dropdownOptions: {
          disabledDialCode: false
        },
        inputOptions: {
          showDialCode: false
        }
      }
    }

  },
  metaInfo () {
    return {
      script: [{
        src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAKbhB0WqtjhuiD1GKt41TomnoxND3ZXj0&libraries=places',
        async: true,
        defer: true,
        callback: () => this.MapsInit() // will declare it in methods
      }]
    }
  },
  watch: {
    date (val) {
      this.partner.date_born = this.formatDate(this.date)
    },
    location (newValue) {
      if (newValue) {
        this.service.getPlacePredictions({
          input: this.location,
          types: ['(cities)']
        }, this.displaySuggestions)
      }
    }
  },
  created () {
    this.state = this.partner.state
  },
  methods: {
    ...mapActions('auth', ['solicitedPartner']),
    ...mapActions('partner', ['toogleEditModal', 'updatePartner', 'partnerChangeState']),
    onInput (number, object) {
      const lang = this.$vuetify.lang
      if (object.valid) {
        this.partner.phone = number.replace(' ', '')
        this.errorPhone = null
      } else {
        this.errorPhone = lang.t('$vuetify.rule.bad_phone', [
          lang.t('$vuetify.phone')
        ])
      }
    },
    cancelShowModal () {
      this.toogleEditModal(false)
    },
    onCountry (event) {
      this.partner.country = event.iso2
      this.countrySelect = event
    },
    MapsInit () {
      this.service = new window.google.maps.places.AutocompleteService()
    },
    solicitPartner () {
      if (this.formValid) {
        if (this.showExtraData && this.partner.state === 'solicited') {
          this.$Swal
            .fire({
              title: this.$vuetify.lang.t('$vuetify.partners.title'),
              text: this.$vuetify.lang.t(
                '$vuetify.messages.warning_state'
              ),
              icon: 'warning',
              showCancelButton: false,
              confirmButtonText: this.$vuetify.lang.t(
                '$vuetify.actions.accept'
              ),
              confirmButtonColor: 'primary'
            })
        } else {
          this.loading = true
          this.partner.avatar = this.partner.avatar === '/assets/avatar/avatar-undefined.jpg' ? null : this.partner.avatar
          this.showExtraData ? this.updatePartner(this.partner).then(() => {
            // window.location.reload()
            this.loading = false
          }).catch(() => {
            this.loading = false
          }) : this.solicitedPartner(this.partner).then(() => {
            // window.location.reload()
            this.loading = false
          }).catch(() => {
            this.loading = false
          })
        }
      }
    },
    async updatePartnerData () {
      if (this.formValid) {
        if (this.showExtraData && this.partner.state === 'solicited') {
          this.$Swal
            .fire({
              title: this.$vuetify.lang.t('$vuetify.partners.title'),
              text: this.$vuetify.lang.t(
                '$vuetify.messages.warning_state'
              ),
              icon: 'warning',
              showCancelButton: false,
              confirmButtonText: this.$vuetify.lang.t(
                '$vuetify.actions.accept'
              ),
              confirmButtonColor: 'primary'
            })
        } else {
          this.partner.avatar = this.partner.avatar === '/assets/avatar/avatar-undefined.jpg' ? null : this.partner.avatar
          if (localStorage.getTypeConnection() === 'admin') { await this.partnerChangeState(this.partner) }
          if (localStorage.getTypeConnection() === 'user') {
          } else {
            await this.updatePartner(this.partner)
          }
        }
      }
    },
    onChangeImage (file) {
      if (this.partner.avatar) {
        if (this.partner.avatar.id) {
          this.partner.avatar.original_url = `data:${file.type};base64,${file.base64}`
          return
        }
      }
      this.partner.avatar = `data:${file.type};base64,${file.base64}`
    },
    displaySuggestions (predictions, status) {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        this.searchResults = []
        return
      }
      this.searchResults = predictions.map(prediction => prediction.description)
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    }
  }
}
</script>

<style scoped>

</style>
