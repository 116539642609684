<template>
  <v-container>
    <app-loading v-show="loadingData" />
    <v-dialog
      v-model="showEditModal"
      max-width="700px"
    >
      <v-card>
        <partner-info :show-extra-data="true" @reloadData="loadData"/>
      </v-card>
    </v-dialog>
    <v-row v-if="!loadingData">
      <v-col
        md="12"
        cols="12"
      >
        <app-data-table
          :title="getTitle"
          :headers="getTableColumns"
          csv-filename="partnerProducts"
          :manager="'partners'"
          :items="partners"
          :options="vBindOption"
          :sort-by="['created_at']"
          :sort-desc="[false, true]"
          :is-loading="isTableLoading"
          :view-new-button="false"
          :view-delete-button="false"
          @edit-row="editPartnerHandler($event)"
        >
          <template v-slot:[`item.state`]="{ item }">
            {{ $vuetify.lang.t('$vuetify.sale.state.' + item.state) }}
          </template>
          <template v-slot:[`item.first_name`]="{ item }">
            <v-avatar>
              <v-img :src="item.avatar ? item.avatar.original_url : `/assets/avatar/avatar-undefined.jpg`"
              />
            </v-avatar>
            {{ item.first_name }}
          </template>
        </app-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import PartnerInfo from '../PartnerInfo'
export default {
  name: 'ListPartners',
  components: { PartnerInfo },
  props: {
    state: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      loadingData: false,
      title: '',
      vBindOption: {
        itemKey: 'id',
        singleExpand: false,
        showExpand: false
      }
    }
  },
  computed: {
    ...mapState('partner', [
      'showEditModal',
      'partners',
      'isTableLoading'
    ]),
    getTitle () {
      const split = this.$route.path.split('/')
      const stateL = split[split.length - 1]
      if (stateL === 'solicited') {
        return this.$vuetify.lang.t('$vuetify.titles.solicited')
      } else {
        return this.$vuetify.lang.t('$vuetify.titles.solicit',
          [this.$vuetify.lang.t('$vuetify.sale.state.' + stateL)])
      }
    },
    getTableColumns () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.state'),
          value: 'state',
          select_filter: true
        }, {
          text: this.$vuetify.lang.t('$vuetify.firstName'),
          value: 'first_name',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.lastName'),
          value: 'last_name'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.email'),
          value: 'email'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.phone'),
          value: 'phone'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.country'),
          value: 'country'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.actions.actions'),
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  watch: {
    state: async function () {
      await this.loadData()
    }
  },
  async created () {
    await this.loadData()
  },
  methods: {
    ...mapActions('partner', ['getPartners', 'openEditModal', 'toogleEditModal']),
    editPartnerHandler ($id) {
      this.openEditModal($id)
    },
    async loadData () {
      const split = this.$route.path.split('/')
      this.state = split[split.length - 1]
      this.loadingData = true
      await this.getPartners({ state: this.state })
      this.loadingData = false
    }
  }
}
</script>

<style scoped>

</style>
